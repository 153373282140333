import React, { useContext } from 'react'
import { Menu as UikitMenu, useModal } from 'penguinfinance-uikit2'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import { usePriceWolfUsdt, usePools } from 'state/hooks'
import WalletConnectGuideModal from 'components/Modal/WalletConnectGuideModal'
import SettingModal from 'components/Modal/SettingModal'

import { config, socials } from './config'

const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const wolfPriceUsd = usePriceWolfUsdt()
  const pools = usePools(account)
  const wolfPool = pools.length > 0 ? pools[0] : null

  const getaWolfToWolfRatio = (pool) => {
    return pool.totalStaked && pool.totalSupply
      ? new BigNumber(pool.totalStaked).div(new BigNumber(pool.totalSupply)).toJSON()
      : 1
  }

  const [onToggleSettingModal] = useModal(
    <SettingModal />,
  )

  const aWolfToWolfRatio = getaWolfToWolfRatio(wolfPool)

  const links = [...config]

  return (
    <>
      <WalletConnectGuideModal />
      <UikitMenu
        account={account}
        login={login}
        logout={logout}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={selectedLanguage && selectedLanguage.code}
        langs={allLanguages}
        setLang={setSelectedLanguage}
        penguinPriceUsd={wolfPriceUsd.toNumber()}
        pefiRatio={Number(aWolfToWolfRatio)}
        links={links}
        socials={socials}
        onSettingClick={onToggleSettingModal}
        {...props}
      />
    </>
  )
}

export default Menu
