import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { useWeb3React } from '@web3-react/core'
import { Toast, toastTypes } from 'penguinfinance-uikit2'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import useUsdtPrice from 'hooks/useUsdtPrice'
import { DAYS_PER_YEAR, CURRENT_NEST_DAILY_REWARDS, CURRENT_V2_NEST_DAILY_REWARDS } from 'config'
import { getBalanceNumber } from 'utils/formatBalance'
import { getV2NestApy } from 'utils/apyHelpers'
import {
  fetchMasterChefwolfPerSec,
  fetchFarmsPublicDataAsync,
  fetchMasterChefRewards,
  fetchCompounderFarmsPublicDataAsync,
  fetchLpsPublicDataAsync,
  fetchPoolsPublicDataAsync,
  fetchPoolsUserDataAsync,
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
  /*
   * v2
   */
  // nest
  fetchV2PoolsPublicDataAsync,
  fetchV2PoolsUserDataAsync
} from './actions'
import {
  State,
  Farm,
  Lp,
  Pool,
  v2Pool,
  GlobalState,
} from './types'


const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchMasterChefwolfPerSec())
    dispatch(fetchFarmsPublicDataAsync())
    // v2
    dispatch(fetchV2PoolsPublicDataAsync())
    dispatch(fetchMasterChefRewards())
    dispatch(fetchCompounderFarmsPublicDataAsync())
    dispatch(fetchLpsPublicDataAsync())
    // POOL REMOVAL
    dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms
export const useWolfPerSec = (): BigNumber => {
  const wolfPerSec = useSelector((state: State) => state.compounderFarms.wolfPerSec)
  return new BigNumber(wolfPerSec)
}

export const useGondolaPerSec = (): BigNumber => {
  const gondolaPerSec = useSelector((state: State) => state.compounderFarms.gondolaPerSec)
  return new BigNumber(gondolaPerSec)
}

export const useLydPerSec = (): BigNumber => {
  const lydPerSec = useSelector((state: State) => state.compounderFarms.lydPerSec)
  return new BigNumber(lydPerSec)
}

export const useJoePerSec = (): BigNumber => {
  const joePerSec = useSelector((state: State) => state.compounderFarms.joePerSec)
  return new BigNumber(joePerSec).div(2)
}

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid, type): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid && f.type === type))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useLPFromSymbol = (lpSymbol: string): Lp => {
  const lp = useSelector((state: State) => state.lps.data.find((f) => f.lpSymbol === lpSymbol))
  return lp
}

export const useFarmUser = (pid, type) => {
  const farm = useFarmFromPid(pid, type)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Compounder Farms
export const useCompounderwolfPerSec = (): BigNumber => {
  const wolfPerSec = useSelector((state: State) => state.compounderFarms.wolfPerSec)
  return new BigNumber(wolfPerSec)
}

export const useCompounderFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.compounderFarms.data)
  return farms
}

export const useCompounderFarmFromPid = (lpSymbol: string, type: string): Farm => {
  const farm = useSelector((state: State) =>
    state.compounderFarms.data.find((f) => f.lpSymbol === lpSymbol && f.type === type),
  )
  return farm
}

export const useCompounderFarmFromSymbol = (lpSymbol: string, type: string): Farm => {
  const farm = useSelector((state: State) =>
    state.compounderFarms.data.find((f) => f.lpSymbol === lpSymbol && f.type === type),
  )
  return farm
}

export const useCompounderFarmUser = (lpSymbol: string, type: string) => {
  const farm = useCompounderFarmFromPid(lpSymbol, type)
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    stakedReceiptBalance: farm.userData ? new BigNumber(farm.userData.stakedReceiptBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
    pendingaWolf: farm.userData ? new BigNumber(farm.userData.pendingaWolf) : new BigNumber(0),
  }
}

// Pools
export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices
export const usePriceAvaxUsdt = (): BigNumber => {
  const { price: usdtPrice } = useUsdtPrice()
  const lpSymbol = 'USDT-AVAX LP' // USDT-AVAX LP
  const lp = useLPFromSymbol(lpSymbol)
  return lp.tokenPriceVsQuote ? new BigNumber(usdtPrice).div(lp.tokenPriceVsQuote) : ZERO
}

export const usePriceWolfUsdt = (): BigNumber => {
  const lpSymbol = 'WOLF-AVAX LP' // WOLF-AVAX LP
  const farm = useFarmFromSymbol(lpSymbol)
  const avaxPriceUSD = usePriceAvaxUsdt()
  return farm.tokenPriceVsQuote ? avaxPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceEthUsdt = (): BigNumber => {
  const lpSymbol = 'WOLF-AVAX LP' // ETH-AVAX LP
  const farm = useFarmFromSymbol(lpSymbol)
  const avaxPriceUSD = usePriceAvaxUsdt()
  return farm.tokenPriceVsQuote ? avaxPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceEthAvax = (): BigNumber => {
  const priceAvaxUsdt = usePriceAvaxUsdt()
  const priceEthUsdt = usePriceEthUsdt()
  return priceEthUsdt.div(priceAvaxUsdt)
}

export const usePricePngUsdt = (): BigNumber => {
  const lpSymbol = 'WOLF-AVAX LP'
  const farm = useFarmFromSymbol(lpSymbol)
  const wolfPriceUSD = usePriceWolfUsdt()
  return farm.tokenPriceVsQuote ? wolfPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceLinkUsdt = (): BigNumber => {
  const lpSymbol = 'WOLF-AVAX LP'
  const farm = useFarmFromSymbol(lpSymbol)
  const wolfPriceUSD = usePriceWolfUsdt()
  return farm.tokenPriceVsQuote ? wolfPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceLydUsdt = (): BigNumber => {
  const lpSymbol = 'USDT-AVAX LP'
  const lp = useLPFromSymbol(lpSymbol)
  return lp.tokenPriceVsQuote ? new BigNumber(1).div(lp.tokenPriceVsQuote) : ZERO
}

export const usePriceJoeUsdt = (): BigNumber => {
  const lpSymbol = 'JOE-USDT LP'
  const lp = useLPFromSymbol(lpSymbol)
  return lp.tokenPriceVsQuote ? new BigNumber(1).times  (lp.tokenPriceVsQuote) : ZERO
}


export const usePriceGdlUsdt = (): BigNumber => {
  const lpSymbol = 'LYD-USDT LP'
  const farm = useLPFromSymbol(lpSymbol)
  const avaxPriceUSD = usePriceAvaxUsdt()
  return farm.tokenPriceVsQuote ? avaxPriceUSD.div(farm.tokenPriceVsQuote) : ZERO
}

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast))

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// Global
export const useGlobal = () => {
  const { wrongNetworkGuideModalOpened }: GlobalState = useSelector((state: State) => state.global)

  return { wrongNetworkGuideModalOpened }
}

// APY/APR
export const useNestAprPerDay = (): number => {
  const nestPool = usePoolFromPid(1)
  const totalStakedInNest = nestPool.totalStaked
  return (CURRENT_NEST_DAILY_REWARDS / getBalanceNumber(totalStakedInNest)) * 100
}

export const useNestApr = (): number => {
  return (DAYS_PER_YEAR * useNestAprPerDay()) / 100
}

export const useNestApy = () => {
  const staticFee = 10
  return (1 + useNestApr() / DAYS_PER_YEAR) ** DAYS_PER_YEAR - 1 + staticFee
}

export const useCompoundApy = ({ normalApy, type }: { normalApy: string; type: string }) => {
  const nestAPY = useNestApr()

  if (!normalApy) return ''
  const _normalApy = Number(normalApy) / 100

  if (type === 'Lydia' || type === 'Pangolin' || type === 'Gondola' || type === 'Joe' || type === 'Native') {
    const compoundApy = (1 + _normalApy / DAYS_PER_YEAR) ** DAYS_PER_YEAR - 1
    return (compoundApy * 100).toFixed(2)
  }

  if (type === 'Penguin') {
    const nestStakingBips = 5000
    const compoundApy1 = (1 + (_normalApy * (1 - nestStakingBips / 10000)) / 730) ** 730 - 1
    const compoundApy2 = (nestStakingBips / 10000) * _normalApy * (((nestAPY / 2) * 729) / 730 + 1)
    return ((compoundApy1 + compoundApy2) * 100).toFixed(2)
  }

  return normalApy
}

export const useV2NestAprPerDay = (): number => {
  const v2NestPool = useV2Pools(null)[0]

  const totalStakedInNest = v2NestPool.totalStaked
  return (CURRENT_V2_NEST_DAILY_REWARDS / getBalanceNumber(totalStakedInNest)) * 100
}

export const useV2NestApr = (): number => {
  return (DAYS_PER_YEAR * useV2NestAprPerDay()) / 100
}

export const useV2NestApy = (dailyApr = 0) => {
  const staticFee = 0
  return (1 + dailyApr) ** DAYS_PER_YEAR - 1 + staticFee
}

export const useV2Pools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchV2PoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)

  return pools.map((pool) => {
    return {
      ...pool,
      apy: new BigNumber(getV2NestApy(pool.dailyApr)),
    }
  })
}
