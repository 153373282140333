import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'WOLF-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0xf298cc1A6f34C52Cc5F6B062F3F1711724E444d7',
    },
    tokenSymbol: 'WOLF',
    tokenAddresses: {
      43113: '',
      43114: '0x55B888f0b2C6c76FC43caf78656033a25fe9F485',
    },
    quoteTokenSymbol: QuoteToken.AVAX,
    quoteTokenAddresses: contracts.wavax,
    withdrawalFee: '0',
    hardApy: '0%',
    type: 'Penguin',
    name: 'pendingTokens'
  },

  {
    pid: 1,
    lpSymbol: 'WOLF-USDT LP',
    lpAddresses: {
      43113: '',
      43114: '0x6ACfbBfbB0b8CEF0A08EF11e009D1b94427Aaa6D',
    },
    tokenSymbol: 'WOLF',
    tokenAddresses: {
      43113: '',
      43114: '0x55B888f0b2C6c76FC43caf78656033a25fe9F485',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAddresses: contracts.usdt,
    withdrawalFee: '0',
    hardApy: '0%',
    type: 'Penguin',
    name: 'pendingTokens'
  },

  {
    pid: 2,
    lpSymbol: 'WOLF-TSD LP',
    lpAddresses: {
      43113: '',
      43114: '0xf42A2b67fD41480747C23E9fdEF539005d68B5D9',
    },
    tokenSymbol: 'TSD',
    tokenAddresses: {
      43113: '',
      43114: '0x4fbf0429599460D327BD5F55625E30E4fC066095',
    },
    quoteTokenSymbol: QuoteToken.WOLF,
    quoteTokenAddresses: contracts.wolf,
    withdrawalFee: '0',
    hardApy: '0%',
    type: 'Penguin',
    name: 'pendingTokens'
  },
  /* {
    pid: 2,
    lpSymbol: 'WOLF-JOE LP',
    lpAddresses: {
      43113: '',
      43114: '0x1bb5541eccda68a352649954d4c8ece6ad68338d',
    },
    tokenSymbol: 'JOE',
    tokenAddresses: {
      43113: '',
      43114: '0x60781C2586D68229fde47564546784ab3fACA982',
    },
    quoteTokenSymbol: QuoteToken.WOLF,
    quoteTokenAddresses: contracts.wolf,
    withdrawalFee: '2.56',
    hardApy: '0%',
    type: 'Penguin',
    name: 'pendingTokens'
  } */

]

export default farms
