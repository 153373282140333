import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import farmsReducer from './farms'
import compounderFarmsReducer from './compounderFarms'
import lpsReducer from './lps'
import toastsReducer from './toasts'
import poolsReducer from './pools'
import globalReducer from './global'
import v2PoolsReducer from './v2pools'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    lps: lpsReducer,
    farms: farmsReducer,
    compounderFarms: compounderFarmsReducer,
    toasts: toastsReducer,
    pools: v2PoolsReducer,
    global: globalReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
