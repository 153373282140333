import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'WOLF-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0xf298cc1A6f34C52Cc5F6B062F3F1711724E444d7',
    },
    tokenSymbol: 'WOLF',
    tokenAddresses: {
      43113: '',
      43114: '0x55B888f0b2C6c76FC43caf78656033a25fe9F485',
    },
    strategyAddress: '0xaa8d4b2024edC546bcfD946F80596DA9F3e74E3f',
    quoteTokenSymbol: QuoteToken.AVAX,
    quoteTokenAddresses: contracts.wavax,
    withdrawalFee: '0',
    hardApy: '0%',
    type: 'Native',
    name: 'pendingTokens',
    },

    {
      pid: 1,
      lpSymbol: 'WOLF-USDT LP',
      lpAddresses: {
        43113: '',
        43114: '0x6acfbbfbb0b8cef0a08ef11e009d1b94427aaa6d',
      },
      tokenSymbol: 'USDT',
      tokenAddresses: {
        43113: '',
        43114: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      },
      strategyAddress: '0x1A3c8be790591C86bb8922E862B8448245F34F05',
      quoteTokenSymbol: QuoteToken.WOLF,
      quoteTokenAddresses: contracts.wolf,
      withdrawalFee: '0',
      hardApy: '0%',
      type: 'Native',
      name: 'pendingTokens',
    },

    {
    pid: 0,
    lpSymbol: 'JOE-AVAX LP',
    lpAddresses: {
      43113: '',
      43114: '0x454E67025631C065d3cFAD6d71E6892f74487a15',
    },
    tokenSymbol: 'JOE',
    tokenAddresses: {
      43113: '',
      43114: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    },
    strategyAddress: '0xAc5600129467d840D921158F06632B7704B5C4e2',
    quoteTokenSymbol: QuoteToken.AVAX,
    quoteTokenAddresses: contracts.wavax,
    withdrawalFee: '4',
    hardApy: '0%',
    type: 'Joe',
    name: 'pendingTokens',
    },
    {
      pid: 39 ,
      lpSymbol: 'USDC-AVAX LP',
      lpAddresses: {
        43113: '',
        43114: '0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1',
      },
      tokenSymbol: 'USDC',
      tokenAddresses: {
        43113: '',
        43114: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      },
      strategyAddress: '0xeb244D3296ca427d4E45Ea2987d945edd9726E73',
      quoteTokenSymbol: QuoteToken.AVAX,
      quoteTokenAddresses: contracts.wavax,
      withdrawalFee: '4',
      hardApy: '0%',
      type: 'Joe',
      name: 'pendingTokens',
      },
      {
        pid: 26 ,
        lpSymbol: 'ETH-AVAX LP',
        lpAddresses: {
          43113: '',
          43114: '0xFE15c2695F1F920da45C30AAE47d11dE51007AF9',
        },
        tokenSymbol: 'ETH',
        tokenAddresses: {
          43113: '',
          43114: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
        },
        strategyAddress: '0xB5365d9C536cA4dF7ea14fa6700080f8940c62F6',
        quoteTokenSymbol: QuoteToken.AVAX,
        quoteTokenAddresses: contracts.wavax,
        withdrawalFee: '4',
        hardApy: '0%',
        type: 'Joe',
        name: 'pendingTokens',
        },

    /* {
      pid: 1,
      lpSymbol: 'USDC-DAI LP',
      lpAddresses: {
        43113: '',
        43114: '0x494Dd9f783dAF777D3fb4303da4de795953592d0',
      },
      tokenSymbol: 'USDC',
      tokenAddresses: {
        43113: '',
        43114: '0x55B888f0b2C6c76FC43caf78656033a25fe9F485',
      },
      strategyAddress: '0xaB6eEd788BEed09D1279b21B6C91F9750242E0f5',
      quoteTokenSymbol: QuoteToken.USDC,
      quoteTokenAddresses: contracts.wavax,
      withdrawalFee: '4',
      hardApy: '0%',
      type: 'Trader Joe',
      name: 'pendingTokens',
    }, */

]

export default farms
