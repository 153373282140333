import joeAvax from 'config/abi/compounder/joeAvax.json'
import usdcAvax from 'config/abi/compounder/usdcAvax.json'
import ethAvax from 'config/abi/compounder/ethAvax.json'
import wolfAvax from 'config/abi/compounder/WolfAvax.json'
import wolfUsdt from 'config/abi/compounder/wolfUsdt.json'
// import usdcDai from 'config/abi/compounder/usdcDai.json'
// import usdcJoe from 'config/abi/compounder/usdcJoe.json'

// import compounderAbi from 'config/abi/compounder.json'

import compounderFarms from 'config/constants/compounderFarms'


const getStrategyAbi = (lpSymbol: string, farmType: string) => {
  const farm = compounderFarms.find((farmItem) => farmItem.lpSymbol === lpSymbol && farmItem.type === farmType)
  // return compounderAbi


  if (farm.lpSymbol === 'JOE-AVAX LP') {
    return joeAvax
  }
  if (farm.lpSymbol === 'USDC-AVAX LP') {
    return usdcAvax
  }
  if (farm.lpSymbol === 'ETH-AVAX LP') {
    return ethAvax
  }
  if (farm.lpSymbol === 'WOLF-AVAX LP') {
    return wolfAvax
  }
  if (farm.lpSymbol === 'WOLF-USDT LP') {
    return wolfUsdt
  }
  return null
}

export default getStrategyAbi
