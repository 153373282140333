import React, { Suspense, lazy } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { ResetCSS } from 'penguinfinance-uikit2'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
// import { useWeb3React } from '@web3-react/core'
import { useFetchPublicData } from 'state/hooks'
import GlobalStyle from 'style/Global'
import Menu from 'components/Menu'
import ToastListener from 'components/ToastListener'
import PageLoader from 'components/PageLoader'
import CurrentBlockWrapper from 'components/CurrentBlockWrapper'
import Pools from 'views/Pools'
import history from './routerHistory'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))

const NotFound = lazy(() => import('./views/NotFound'))
const Soon = lazy(() => import('./views/ComingSoon'))

const CompounderIgloos = lazy(() => import('./views/CompounderIgloos'))
const Nest = lazy(() => import('./views/Nest'))


// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const HomeBgContainer = styled.div`
  background-image: ${({ theme }) =>
    theme.isDark ? "url('/images/home/bg_dark.png')" : "url('/images/home/bg_light.png')"};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom right;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: -1;
`

const App: React.FC = () => {
  useFetchPublicData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <HomeBgContainer />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/compounder">
              <CompounderIgloos />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/den">
              <Nest />
            </Route>
            <Route path="/grounds">
              <Soon />
            </Route>
            <Route path="/strategies">
              <Soon />
            </Route>
            <Route path="/nftyield">
              <Soon />
            </Route>
            <Route path="/hall">
              <Soon />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
      <ToastListener />
      <CurrentBlockWrapper />
    </Router>
  )
}

export default React.memo(App)
