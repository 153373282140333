import { MenuEntry } from 'penguinfinance-uikit2'

export const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Buy $WOLF',
    icon: 'TradeIcon',
    href: 'https://www.traderjoexyz.com/#/trade?outputCurrency=0x55B888f0b2C6c76FC43caf78656033a25fe9F485',
  },
  {
    label: 'Farms',
    icon: 'PoolIcon',
    href: '/farms',
  },
  {
    label: 'Hunting Grounds',
    icon: 'IfoIcon',
    href: '/grounds',
  },
  {
    label: 'Compounder',
    icon: 'NftIcon',
    href: '/compounder',
  },
  {
    label: "Wolf's Den",
    icon: 'MoonIcon',
    href: '/den',
  },
  {
    label: 'Yield Strategies',
    icon: 'GroupsIcon',
    href: '/strategies',
  },
  {
    label: 'NFT Yield',
    icon: 'AuditIcon',
    href: '/nftyield',
  },
  {
    label: 'Hall of fame (NFTs)',
    icon: 'CrownIcon',
    href: '/hall',
  },

  {
    label: 'Learn More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.alphafinance.app',
      },
      {
        label: 'Contracts',
        href: 'https://docs.alphafinance.app/info/smart-contracts',
      },
      {
        label: '$WOLF Chart',
        href: 'https://chartex.pro/?symbol=AVAX_TRADERJOE%3AWOLF.0xf298cc1A6f34C52Cc5F6B062F3F1711724E444d7&interval=3&theme=dark',
      },
    ],
  },

]

export const socials = [
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/avax_alphafinance',
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/avax_alphafi',
  },
]
