import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 1,
    tokenName: 'WOLF',
    stakingTokenName: QuoteToken.WOLF,
    stakingTokenAddress: '0x55B888f0b2C6c76FC43caf78656033a25fe9F485',
    contractAddress: {
      43113: '0xF21dD579489520C6E4C8aD273f41D343Fa9d5b2F',
      43114: '0x73752E5EB4f12BC84a8cBF1C41Af476990674c86',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://penguinfinance.org/',
    penguinNestsGuideLink: 'https://penguin-finance.gitbook.io/penguin-finance/summary/penguin-nests-staking-and-fee-collection',
    harvest: true,
    tokenPerBlock: '7',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
]

export default pools
