export default {
  wolf: {
    43113: '',
    43114: '0x55B888f0b2C6c76FC43caf78656033a25fe9F485',
  },
  masterChef: {
    43113: '',
    43114: '0x8Af9b99540BCbef173C9Fa858400e9ba59D15630',
  },
  gondolaMasterChef: {
    43113: '',
    43114: '0x34C8712Cc527a8E6834787Bd9e3AD4F2537B0f50',
  },
  lydiaMasterChef: {
    43113: '',
    43114: '0xFb26525B14048B7BB1F3794F6129176195Db7766',
  },
  wavax: {
    43113: '',
    43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  },
  lottery: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  lotteryNFT: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  mulltiCall: {
    43113: '',
    43114: '0x594052B0A068Ec1F242dD71872cc69fEcB8F6840',
  },
  busd: {
    43113: '',
    43114: '0xaEb044650278731Ef3DC244692AB9F64C78FfaEA',
  },
  usdt: {
    43113: '',
    43114: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  },
  usdc: {
    43113: '',
    43114: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
  },
  pancakeProfile: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  pancakeRabbits: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnyFactory: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  eth: {
    43113: '',
    43114: '0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
  },
  claimRefund: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  pointCenterIfo: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnySpecial: {
    43113: '',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  aWolf: {
    43113: '',
    43114: '0x73752E5EB4f12BC84a8cBF1C41Af476990674c86',
  },
  png: {
    43113: '',
    43114: '0x60781C2586D68229fde47564546784ab3fACA982',
  },
  link: {
    43113: '',
    43114: '0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651',
  },
  zeth: {
    43113: '',
    43114: '0xf6f3eea905ac1da6f6dd37d06810c6fcb0ef5183',
  },
  zusdt: {
    43113: '',
    43114: '0x650cecafe61f3f65edd21efacca18cc905eef0b7',
  },
  lyd: {
    43113: '',
    43114: '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084',
  },
  gdl: {
    43113: '',
    43114: '0xd606199557c8ab6f4cc70bd03facc96ca576f142',
  },
  joe: {
    43113: '',
    43114: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
  },
  joeMasterChef: {
    43113: '',
    43114: '0xd6a4f121ca35509af06a0be99093d08462f53052',
  },
  pangolinManager: {
    43113: '',
    43114: '0x912b5D41656048Ef681eFa9D32488a3fFE397994',
  },

  // emperor
  emperor: {
    43113: '',
    // 43114: '0xfd162b5733b01C0b6aD7575A0A18a8EA3Fd69A83',
    // 43114: '0xF36DA227A479a49D823Af1E7b3df632A698a9Ac6',
    43114: '0xB8bcBfc4Aed1aE1B166c5197e054Fc0D9833D5e5',
  },
  emperorPenguinDB: {
    43113: '',
    43114: '0x5B0A609AdA79F37054cBeEb35C00cC64B748f1eF',
  },

  // covid emperor
  withoutBorders: {
    43113: '',
    43114: '0xCDcdF40215929d5b73Fa566791B19832ce1B4b51',
  },
  charityPenguinDB: {
    43113: '',
    43114: '0xaAD41CCf76c035DAF80d662584823215f2e0487D',
  },
}
