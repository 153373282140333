import React, { useState } from 'react'

const CACHE_VISIBLE_BLOCK_KEY = 'VISIBLE_BLOCK'

const SettingContext = React.createContext({
  visibleBlock: null,
  toggleVisibleBlock: () => null,
})

const SettingContextProvider = ({ children }) => {

  const [visibleBlock, setVisibleBlock] = useState(() => {
    const visibleBlockUserSetting = localStorage.getItem(CACHE_VISIBLE_BLOCK_KEY)
    return visibleBlockUserSetting ? JSON.parse(visibleBlockUserSetting) : false
  })

  const toggleVisibleBlock = () => {
    setVisibleBlock((prevState) => {
      localStorage.setItem(CACHE_VISIBLE_BLOCK_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }

  return (
    <SettingContext.Provider value={{ visibleBlock, toggleVisibleBlock }}>
      {children}
    </SettingContext.Provider>
  )
}

export { SettingContext, SettingContextProvider }
